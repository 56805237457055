/* General Page Styling */
.database-page-container {
    background-color: #f5f7fa;
    color: #2f3b4a;
    font-family: 'Arial', sans-serif;
    height: 70vh;
    padding-top: 20px;
}

/* Header */
.database-page-header {
    text-align: center;
    font-size: 3rem;
    color: #2f3b4a;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 30px;
}

/* Search Bar */
.database-page-search-bar {
    width: 100%;
    /* max-width: 80vw; */
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #2f3b4a;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #2f3b4a;
    background-color: #f5f7fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.database-page-search-bar::placeholder {
    color: #8892a4;
}

/* Filters */
.database-page-filters {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
}

.database-page-filter-select {
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #2f3b4a;
    border-radius: 5px;
    background-color: #f5f7fa;
    color: #2f3b4a;
}

.database-page-filter-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #2f3b4a;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.database-page-filter-button:hover {
    background-color: #1e2b3a;
}

/* Exercise List */
.database-page-exercise-list {
    list-style-type: none;
    padding: 0;
}

.database-page-exercise-item {
    background-color: white;
    border: 1px solid #2f3b4a;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.database-page-exercise-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.database-page-exercise-item h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #2f3b4a;
}

.database-page-exercise-item p {
    font-size: 1rem;
    color: #2f3b4a;
}

/* Spinner Styling */
.database-page-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #2f3b4a;
}

.database-page-spinner div {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    animation: database-page-pulse 1.5s infinite;
}

@keyframes database-page-pulse {
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
}


.exercise-info-input{
    font-size: 1.75em;
    max-width: 70%;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 5px 10px;
    width: 50vw;
}

.save-exercise-button{
    font-size: 1.25vw;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 5px 10px;
}

.step-title{
    font-weight: bold;
    font-size: 2em;
    text-align: center;
}

.tab{
    display:flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    scale: 1;
    transition: 0.3s;
}

.tab:hover{
    scale: 1.05 1;
    cursor: pointer;
    transition: 0.3s;
}

.tab-label{
    scale: 1;
    transition: 0.3s;
}

.tab-label:hover{
    cursor: pointer;
}

.database-page-exercise-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    list-style-type: none;
    padding: 0;
}

.database-page-exercise-item {
    cursor: pointer;
}

.exercise-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.exercise-card:hover {
    transform: translateY(-5px);
    cursor: pointer;;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.exercise-card-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.exercise-card-content {
    padding: 16px;
}

.exercise-card-title {
    font-size: 1.5em;
    margin-bottom: 8px;
    color: #333;
}

.exercise-card p {
    margin: 4px 0;
    color: #666;
}

.exercise-card p strong {
    color: #333;
}

.condition-list {
    list-style-type: none;
    padding: 0;
  }
  
  .condition-item {
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  
  .condition-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    transition: box-shadow 0.3s ease;
    background-color: #f9f9f9;
  }
  
  .condition-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .condition-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .condition-body {
    margin-top: 0.5rem;
  }
  
  .condition-description {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
  }
  
  .condition-symptoms {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .card-symptom-item {
    display: inline-block;
    background-color: #e0f7fa;
    color: #00796b;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .symptom-more {
    font-style: italic;
    color: #00796b;
  }
  
  .no-symptoms {
    color: #999;
    font-size: 0.9rem;
  }
  
  .medication-list {
    list-style-type: none;
    padding: 0;
  }
  
  .medication-item {
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  
  .medication-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    transition: box-shadow 0.3s ease;
    background-color: #f9f9f9;
  }
  
  .medication-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .medication-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
  }

  .symptom-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
  }
  
  .symptom-item {
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .symptom-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .symptom-icon {
    margin-right: 10px;
    color: #ff6961; /* A red icon for indicating removal */
  }
  
  .symptom-description {
    font-size: 16px;
    color: #333;
  }
  
.table-row{

}

.table-row:hover{
  background-color: steelblue;
  cursor: pointer;
}